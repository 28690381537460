/*
 * @Author: gaojingran
 * @Date: 2021-05-07 14:19:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-13 16:34:13
 * @Description: oss custom upload
 */

import oss from 'ali-oss'
import withAsync from '@/mixins/withAsync'
import { uuid } from '@/utils/utils'

export default {
  mixins: [withAsync],
  props: {
    /**
     *  文件列表 object[]
     *  uid
        name      // 文件名
        status    // 状态有：uploading done error
        percent   // 上传进度
        url       // 下载链接
     * */
    value: {},
    // 数据排序方式
    sortType: {
      type: String,
      default: 'push',
      validator(value) {
        return ['push', 'unshift'].indexOf(value) !== -1
      },
    },
    // 获取 ali oss token地址
    ossTokenApi: {
      type: String,
      default: 'aliOssStsToken',
    },
    dir: {
      type: String,
      default: 'order-file/',
    },
  },
  methods: {
    // 阿里 oss 上传
    async customRequest({ file }) {
      // 数量限制
      if (this.value.length >= this.maxLength) {
        return false
      }

      const uid = uuid()
      try {
        let newValue = []
        if (this.sortType === 'push') {
          newValue = [
            ...this.value,
            {
              uid,
              name: file.name,
              status: 'uploading',
              percent: 0,
              url: undefined,
            },
          ]
        } else {
          newValue = [
            {
              uid,
              name: file.name,
              status: 'uploading',
              percent: 0,
              url: undefined,
            },
            ...this.value,
          ]
        }

        this.$emit('input', newValue)

        const { accessKeyId, accessKeySecret, securityToken, region, bucket, host, dir } = await this.$http(
          this.ossTokenApi,
          {
            dir: this.dir,
          }
        )

        const client = new oss({
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
          region,
          bucket,
        })

        // 添加 client
        this.$emit(
          'input',
          this.value.map((v) => {
            if (v.uid === uid) {
              return {
                ...v,
                ossClient: client,
              }
            } else {
              return v
            }
          })
        )

        client
          .multipartUpload(dir + uid, file, {
            progress: (progress) => {
              // 更新 progress
              this.$emit(
                'input',
                this.value.map((v) => {
                  if (v.uid === uid) {
                    return {
                      ...v,
                      ossClient: client,
                      percent: Math.floor(progress * 100),
                    }
                  } else {
                    return v
                  }
                })
              )
            },
            headers: {
              'content-disposition': `attachment; filename=${encodeURIComponent(file.name)}`,
            },
          })
          .then((result) => {
            console.log('result :>> ', result)
            // 添加url
            this.$emit(
              'input',
              this.value.map((v) => {
                if (v.uid === uid) {
                  return {
                    ...v,
                    ossClient: client,
                    percent: 100,
                    status: 'done',
                    url: `${host}/${result.name}`,
                    size: file.size,
                  }
                } else {
                  return v
                }
              })
            )
          })
          .catch((err) => {
            this.$emit(
              'input',
              this.value.filter((v) => v.url)
            )
            err.message && this.$message.error('当前网络不佳，请稍后再试')
            // err.message && this.$message.error(err.message)
            throw err
          })
      } catch (err) {
        // error
        this.$emit(
          'input',
          this.value.map((v) => {
            if (v.uid === uid) {
              return {
                ...v,
                percent: 100,
                status: 'error',
              }
            } else {
              return v
            }
          })
        )
      }
    },

    // delete
    handleDeleteFile(uid) {
      const cur = this.value.find((v) => v.uid === uid)
      if (cur && cur.ossClient) {
        // 停止上传
        cur.ossClient.cancel()
      }
      this.$emit(
        'input',
        this.value.filter((v) => (v.uid || v.id) !== uid)
      )
    },
  },
}
